import Swiper, {Autoplay, Navigation, Pagination, Scrollbar} from 'swiper'

/**
 * начиная с версии 5.x различные модули необходимо
 * импортировать и подключать отдельно, это касается и
 * javascript и стилей, это рекомендованный подход
 */
Swiper.use([Navigation, Pagination, Scrollbar, Autoplay])

/**
 * стили библиотеки подключать всегда в общий поток стилей
 * main.sass/libs, иначе они будут выделены
 * в отдельный "чанк" и не будут подключены
 * в стили шаблона.
 */

require('./style.scss')

export function start(selector = '.swiper-hero'): Swiper|undefined {
  if (!document.querySelector(selector)) return
  /**
   * @see https://swiperjs.com/api/
   */
  return new Swiper(selector, {
    watchSlidesProgress: true,
    slidesPerView: 1,
    breakpoints: {},
    loop: true,
    autoplay: {
      delay: 5000
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      dynamicBullets: true
    },
    scrollbar: {
      el: '.swiper-scrollbar'
    }
  })
}
