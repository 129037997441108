<template>
  <div class="site-order-form section">
    <validation-observer v-slot="{invalid, handleSubmit}" ref="observer">
         <div class="form-header" ref="header" v-if="isNotSuccess">
        <p class="title is-4" ref="title">
        Заказать проект
        </p>
      </div>
      <div class="form-messages" ref="messages">
        <b-notification type="is-danger" @close="onCloseNotification" has-icon="" v-model="status.error">
          <p>Ошибка! Не удалось отправить сообщение.</p>
          <p>Попробуйте снова, если ошибка повторяется, обновите страницу.</p>
          <template v-if="showExtendedError">
            <p>Описание ошибки:</p>
            <span v-for="(item, index) in errors" :key="index">
              <strong>{{index}}: {{item}}</strong>
            </span>
          </template>
        </b-notification>
        </div>

    <b-notification type="is-success" @close="onCloseNotification" has-icon="" v-model="status.success">
      <p>Спасибо! Ваше сообщение отправлено.</p>
      <p>Постараемся ответить как можно быстрее.</p>
    </b-notification>

<div class="form-wrapper" v-if="isNotSuccess">
  <form @submit.prevent.stop="handleSubmit(submit)" class="form" ref="form" enctype="multipart/form-data">
    <input type="hidden" name="WEB_FORM_ID" :value="formId">
    
    <input name="form_hidden_57" :value="pageUrl" type="hidden">

        <validation-provider v-slot="v" rules="required" name="Ваше имя" slim>
          <p class="has-text-danger is-size-7">{{v.errors[0]}}</p>
          <b-field :type="{ 'is-danger': v.invalid && v.dirty }"  class="mb-4">
            <b-input name="form_text_55" placeholder="Ваше имя *" v-model="formData.name"></b-input>
          </b-field>

        </validation-provider>

        <validation-provider v-slot="v" name="Телефон" rules="required" slim>
          <p class="has-text-danger is-size-7">{{v.errors[0]}}</p>
          <b-field :type="{ 'is-danger': v.invalid && v.dirty }" class="mb-4">
            <b-input name="form_text_54" placeholder="Телефон *" v-model="formData.phone"></b-input>
          </b-field>

        </validation-provider>

        <validation-provider v-slot="v" rules="required|email" name="Email" slim>
          <p class="has-text-danger is-size-7">{{v.errors[0]}}</p>
          <b-field :type="{ 'is-danger': v.invalid && v.dirty }" class="mb-4">
            <b-input name="form_email_53" placeholder="Email *" v-model="formData.email"></b-input>
          </b-field>
        </validation-provider>
 
        <validation-provider v-slot="v" rules="required" name="Услуга" slim>
          <p class="has-text-danger is-size-7">{{v.errors[0]}}</p>
          <b-field :type="{ 'is-danger': v.invalid && v.dirty }"  class="mb-4" label="Проект">
            <b-input name="form_text_56" placeholder="Проект" :value="pageTitle" readonly></b-input>
          </b-field>

        </validation-provider>


        <validation-provider v-slot="v" rules="max:2000" slim name="Сообщение">
          <p class="has-text-danger is-size-7">{{v.errors[0]}}</p>
          <b-field type="{'is-danger': v.invalid}" >
            <b-input name="form_textarea_52" rows="2" placeholder="Сообщение" type="textarea" v-model="formData.message"></b-input>
          </b-field>

        </validation-provider>





    <div style="margin-top: 1rem;" class="is-dark notification">
      <p class="help">
        Отправляя сообщение вы принимаете <a target="_blank" href="/personal-data/">Положение на обработку персональных данных и получение информации.</a>. Если вы не согласны <a target="_blank" href="/personal-data/">с условиями
              положения</a>, не отправляйте форму. Ваши данные будут использованы только для связи с вами, для ответа на ваше сообщение.
      </p>
    </div>

    <div class="controls">
      <b-button type="is-danger " native-type="submit" :disabled="invalid" :class="{'is-loading': isLock}">Отправить</b-button>
    </div>
  </form>

</div>
</validation-observer>

<!--
    /**
      * vue-portal позволяет отрисовать виджет/компонент
      * в любом месте приложения или вне приложения на странице.
      *
      * Преимущества перед обычным монтированием, как изолированное
      * приложение, в том, что сохранятся возможность влиять на
      * поведение компонента.
      * <MountingPortal mountTo=".feedback-portal" multiple>
      *   <b-button @click.prevent="click">Обратная связь</b-button>
      * </MountingPortal>
      */
    -->
</div>
</template>
<script>
  import Vue from 'vue'
  import FormCommonMixin from '@/mixins/form-common-mixin'

  export default Vue.extend({
    name: 'order-form',
     props:{
     /**
     * Адрес страницы продукта
     * на сайте
     */
    pageUrl: {
      type: String,
      required: true,
      default: ''
    },
    /**
     * Название страницы
     */
    pageTitle: {
      type: String,
      required: true,
      default: ''
    },
  },
    mixins: [FormCommonMixin],
    data() {
      return {
        /**
         * Без указания правильного идентификатора
         * данные не будут сохранены. И идентификатор формы и
         * названия полей должны строго соответствовать настройкам
         * сайта.
         */
        formId: 6,
        products: [],
        /**
         * Набор стандартных полей создается
         * в "миксине" src/mixins/form-common-mixins.js
         *
         * Чтобы переопределить или дополнить данные,
         * надо создать данные непосредственно здесь
         * в компоненте. Если создавать одинаковые ключи,
         * они будут переопределены, если новые ключи, то
         * данные будут объединены.
         *
         * Ключ с данными формы - `formData`,
         * в нем добавлены часто используемые в формах ключи
         *
         * - name
         * - email
         * - phone
         * - message
         * - file
         *
         * Например чтобы добавить поле Адрес:
         *
         * ```js
         * data() {
         *   ...
         *   formData: {
         *     address: ''
         *   }
         *   ...
         * }
         *
         * Все данные будут объединены вместе. Конечный объект
         * будет содержать все ключи
         *
         * ```js
         * {
         *    name: '',
         *    email: '',
         *    phone: '',
         *    message: '',
         *    file: '',
         *    address: ''
         * }
         * ```
         *
         * Важно понимать, что на сервер отправляются все поля
         * формы, но сохраняются только поля, которые имеют
         * идентификаторы битрикс.
         *
         */
        formData: {
          //product: ''
        },

        /**
         * Возможность заполнять демо-данными
         * форму по нажатию комбинаций клавиш.
         */
        demoShortcuts: false,

        /**
         * демо данные для тестирования
         *
         * Для "ручного" тестирования формы, чтобы каждый раз
         * не набирать данные в полях формы. Можно использовать
         * демо данные. Необходимо создать все ключи/поля формы,
         * соответствующие `formData`.
         *
         */
        demo: {
          /**
           * набор для проверки - CTRL+.
           */
          valid: {
            name: 'Demo Name',
            email: 'demo@diera.ru',
            phone: '+7 000 000-00-00',
            message: 'Demo message.'
          },
          /**
           * набор для проверки ошибок - CTRL+,
           */
          invalid: {
            name: 'Demo Name',
            email: 'demo@diera',
            phone: '+7 000 000-00-00',
            message: 'Demo message.'
          }
        }
      }
    },
    computed: {

     selectedProduct() {
        const vm = this
        if (!this.formData.product) return null
        return this.products.find((x) => {
          return x.ID === vm.formData.product
        })
      }
    },
    mounted() {
      //console.log('mounted')
      const vm = this
      /**
       * загрузка данных из Битрикс
       * для отображения реальных данных
       */
      window['BX'].ajax.runAction('platform:main.api.catalog.getProductSelector')
        .then(({ data }) => {
          vm.products = data.section
        })
        .catch(({ errors }) => console.log(errors))
    },
    methods: {
      /**
       * при рекурсивных ссылках
       * больше проблем чем пользы
       *
       * - <MountingPortal /> вызывается каждый раз при открытии формы
       * - Надо задавать имя и рег. компонент глобально
       *
       * @todo это только пример (НЕ использовать)
       */
      // click() {
      //   this.$buefy.modal.open({
      //     component: 'order-form'
      //   })
      // }
      submit() {
        if (!window['BX']) {
          console.error('BX not found.')
          return
        }

        /**
         * перед отправкой необходимо
         * очистить ошибки и состояния
         */
        this.status.dirty = true
        this.status.success = false
        this.status.error = false
        this.status.pristine = false
        this.errors.splice(0, this.errors.length + 1)

        const form = new FormData(this.$refs.form)
        this.$store.commit('lock')

        window['BX']
          .ajax
          .runAction('platform:main.api.catalog.submitOrder', {
            data: form
          })
          .then(() => {
            console.log('success')

            this.$store.commit('unlock')
            this.status.success = true
            this.resetForm()

          })
          .catch((errors) => {
            console.log('error')

            /**
             * @todo parse errors
             */
            this.$store.commit('unlock')
            this.errors.push(errors)
            this.status.error = true
          })
      }
    }
  })
</script>

<style lang="sass" scoped>
@import 'helpers'
.site-order-form.section
  padding: 1.4rem
  background: $grey-dark
.site-order-form
  .label
    color: #fff!important
.form-header
  margin-bottom: 1rem
  padding-bottom: 1rem
  border-bottom: 1px dotted #ddd
.field
  margin-bottom: 0
  margin-top: 0.5rem
.form-in-modal .platform-feedback-form
  padding: 0
  border: none!important
  .form-wrapper
    background: #fff
    padding: 0 2rem 2rem
  .form-header
    background: $primary
    p.title
      padding-left: 2rem
      padding-top: 1rem
      color: $primary-invert
</style>
