
import Vue, {VNode, VueConstructor} from 'vue'

/**
 * Базовый класс для `Platform` и `Site`.
 *
 * Основная задача - инициализировать отдельные компоненты и
 * сохранить ссылки на них. Дополнительно это место для
 * хранения различных общих утилит, общих настроек. Возможно
 * для сохранения ссылки на внешнюю шину событий.
 *
 * Использование класса может показаться избыточным для
 * "просто" сайта. Но пока лучшего решения нет. Слишком много
 * разнородной логики.
 *
 * Есть две точки инициализации приложения это
 *
 * - `{@/platform|@/site}/index.ts`
 * - `@/runtime/index.ts`
 *
 * Файлы/Модули подключаются в `@/main.ts`.
 *
 * Использование
 *
 * ```ts
 * export class Site extends PlatformBase {}
 * ```
 *
 */
export class PlatformBase {
  /**
   * объект со ссылками на все
   * инициализированные приложения,
   * элементы DOM, или результаты
   */
  collection = {}

  /**
   * Имена зарегистрированных глобально
   * компонентов
   */
  components: string[] = []

  /**
   * Подключение компонента на страницу. По возможности для уникальных
   * компонентов используйте для `selector` идентификатор, чтобы гарантировать
   * уникальность компонента на странице. Если компонентов много, то
   * используется css-класс или другой css-селектор.
   *
   *
   * @param selector {string} css-селектор (идентификатор элемента)
   * @param Component {VueConstructor}
  */
  mountComponent(selector: string, Component: VueConstructor): Vue|boolean {
    if (!document.querySelector(selector)) return false
    return new Vue({
      render: (h): VNode => h(Component)
    }).$mount(selector)
  }

  /**
   *
   * Монтирование компонента с параметрами
   *
   * Монтирование триггеров на страницу. Триггер - HTML-элемент, обычно
   * кнопка для открытия формы. Для подключения, как правило, используется пустой
   * HTML-элемент (div, span).
   *
   * ```html
   * <div class="js-callback-form-trigger">
   *   <feedback-form-trigger title="Feedback"></feedback-form-trigger>
   * </div>
   * ```
   *
   * По-умолчанию class - `js-callback-form-trigger`. То есть если
   * запустить без параметров триггер примонтируется на элементы с
   * классом `js-callback-form-trigger`.
   *
   * Всегда используйте css-классы, не используйте `id` для триггеров.
   *
   * @param selector {string}  css-селектор
   * @param componentName {string}
   * @param Component {VueConstructor}
   *
   */
  mountAsTrigger(selector: string, componentName: string, Component: VueConstructor): NodeListOf<HTMLElement>|[] {
    const elements: NodeListOf<HTMLElement> = document.querySelectorAll(selector)
    elements.forEach(el => new Vue({ components: { [componentName]: Component } }).$mount(el))
    return elements || []
  }

 /**
   * Alias for mountAsTrigger
   */
  mountAsApp(selector: string, componentName: string, Component: VueConstructor): NodeListOf<HTMLElement>|[] {
    return this.mountAsTrigger(selector, componentName, Component)
  }

  /**
   * Монтирование без параметров с заменой элемента.
   *
   * Монтирование триггеров на страницу. Триггер - HTML-элемент, обычно
   * кнопка для открытия формы. Для подключения обычно используется пустой
   * HTML-элемент (div, span).
   *
   * ```html
   * <div class="js-callback-form-trigger"></div>
   * ```
   *
   * По-умолчанию class - `js-callback-form-trigger`. То есть если
   * запустить без параметров триггер примонтируется на элементы с
   * классом `js-callback-form-trigger`.
   *
   * Всегда используйте css-классы, не используйте `id`.
   *
   * @param selector {string} css-селектор
   * @param Component {VueConstructor}
   *
   */
  renderAsTrigger(selector: string, Component: VueConstructor): NodeListOf<HTMLElement>|[] {
    const elements: NodeListOf<HTMLElement> = document.querySelectorAll(selector)
    elements.forEach(el => new Vue({ render: (h) :VNode => h(Component)}).$mount(el))
    return elements || []
  }

  /**
   * При использовании `vue-portal` необходимо
   * зарегистрировать компоненты глобально,
   * чтобы иметь возможность ссылаться на них
   * рекурсивно.
   *
   * И конечно можно использовать регистрацию,
   * просто для использования компонентов внутри других
   * компонентов без необходимости прямого импорта.
   *
   */
  registerComponents(components: {component: VueConstructor, name: string}[]): void {
    /**
     * Пример регистрации компонента.
     * После этого можно использовать `<feedback-form />`
     * в любых шаблонах компонентов.
     */
    components.forEach(el => {
      if (this.components.indexOf(el.name) < 0) {
        this.components.push(el.name)
        Vue.component(el.name, el.component)
      }
    });
  }
}
