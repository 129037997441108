<template>
  <a @click.stop.prevent="openForm()" class="site-order-form-trigger button" :class="customClass">
    <slot name="title">
      <span class="button-title">
         {{title}}
      </span>
    </slot>
    <slot name="icon" v-if="isNotHiddenIcon">
      <span class="icon">
        <i class="fas fa-comment-dots"></i>
      </span>
    </slot>
  </a>
</template>
<script>
import Vue from 'vue'
import ConsultantFormModal from "@/site/components/consultant/ConsultantFormModal";
import FormTriggerMixin from "@/mixins/form-trigger-mixin";

export default Vue.extend({
  // name: 'feedback-form-trigger',
  mixins: [FormTriggerMixin],
      props:{
     /**
     * Адрес страницы продукта
     * на сайте
     */
    pageUrl: {
      type: String,
      required: true,
      default: ''
    }
  },
  methods: {
    openForm() {
      // this.$store.commit('modalOn')
      this.$buefy.modal.open({
        component: ConsultantFormModal,
        width: 640,
        /**
         * всегда добавляем класс,
         * чтобы иметь возможность
         * переопределения стилей
         * для форм в модальном окне
         *
         * Класс может быть любым. Например
         * специально для формы обратной связи:
         * `form-in-modal--feedback`
         */
        customClass: 'form-in-modal',
        canCancel: ['x'],
        /**
         * опция используется для определения
         * что форма открыта в модальном окне,
         * это не стандартная опция она добавлена
         * специально в форму, и отслеживается
         * при закрытии уведомления об успешной
         * отправке
         */
        props: {
          trigger: true,
          pageUrl: this.pageUrl || ''
        }
      })
    }
  }
})
</script>
