import Vue from 'vue'
import { store } from '@/store'
import { CallbackForm } from '@/platform/components'
import { StickyNavbar }  from './sticky'
/**
 * управление поведением `navbar`
 *
 * компонент: /local/components/platform/navbar
 *
 * Основная логика в том, что Vue "оборачивает"
 * существующий DOM-элемент. Это дает возможность
 * добавлять любой функционал в панель. Например добавлять
 * кнопки отрытия модальных окон с формами.
 *
 * Также функциональность самой панели находиться
 * в методах и "состоянии" Vue.
 *
 * Связь с общим хранилищем отрывает "безграничные" возможности
 * интеграции, например создание "корзины заказов".
 *
 * В новой версии вместо ScrollMagic используется gsap.scrollTrigger.
 * @see https://greensock.com/docs/v3/Plugins/ScrollTrigger
 */

export function start (): void {
  /**
   * подразумевается что на странице
   * только один элемент nav#navbar-top
   *
   * если необходимо инициализировать несколько панелей, то
   * надо использовать отдельные файлы/модули или инициализировать
   * панели в цикле (for)
   */
  new Vue({
    store,
    el: 'nav#navbar-top',
    data(): { active: boolean, sticky: StickyNavbar|null } {
      return {
        active: false as boolean,
        sticky: null
      }
      //const elem = document.getElementsByTagName( 'html' )[0];
    },
    mounted() {
      /**
       * управление "прикреплением" меню
       */
      this.sticky = new StickyNavbar()
    },
    methods: {
      /**
       * добавление класса
       * к меню при клике на `burger`
       */
       
      //toggle(): boolean {
        //return this.active = !this.active
     // },
      toggle (){
        const elemHtml = document.getElementsByTagName( 'html' )[0];
        elemHtml.classList.toggle("is-clipped");
        this.active = !this.active
      },
      callback (): void {
        /**
         * лучший вариант использовать
         * отдельные компоненты в модальном окне
         */
        const modal = this.$buefy.modal.open({
          parent: this,
          component: CallbackForm,
          canCancel: ['x'],
          width: 720
        })

        /**
         * после того, как данные успешно отправлены
         * на сервер, необходимо скрыть кнопку закрытия окна
         * вынудив пользователя нажать кнопку "ok", чтобы
         * явно сбросить настройки формы.
         *
         * Другой вариант полностью разрушать форму
         * при закрытии destroyOnHide: true
         */
        modal.$on('success', () => {
          // modal.$props.canCancel = false
        })
      }
    }
  })
}
