/**
 * Функциональность сайта. Сохранение ссылок в
 * одном объекте позволяет лучше контролировать процессы.
 * А также управлять жизненным циклом приложений.
 *
 * примеры можно посмотреть в `src/platform/index.ts`
 */
import {
  FeedbackForm,
  FeedbackFormTrigger,
} from '@/site/components/feedback';
import {
  ConsultantForm,
  ConsultantFormTrigger
} from '@/site/components/consultant';
import {
  CertificateForm
} from '@/site/components/certificate';
import {
  OrderForm,
  OrderFormTrigger,
} from '@/site/components/order';
import { PlatformBase } from "@/platform/platform";

 export class Site extends PlatformBase {
    constructor() {
      super()
      this.mount()
    }

    mount(): void {
    console.log('mount site (empty)');
    this.collection['consultantForm'] = this.mountAsApp('#site-consultant-form','SiteConsultantForm', ConsultantForm)
    this.collection['certificateForm'] = this.mountAsApp('#site-certificate-form','SiteCertificateForm', CertificateForm)
    this.collection['feedbackForm'] = this.mountComponent('#site-feedback-form', FeedbackForm)
    this.collection['feedbackTriggers'] = this.mountAsTrigger('.js-feedback-trigger', 'FeedbackFormTrigger', FeedbackFormTrigger) 
    //this.collection['consultantForm'] = this.mountComponent('#site-consultant-form', ConsultantForm)
    this.collection['consultantTriggers'] = this.mountAsTrigger('.js-consultant-trigger', 'ConsultantFormTrigger', ConsultantFormTrigger)
    this.collection['orderForm'] = this.mountComponent('#site-order-form', OrderForm)
    this.collection['orderTriggers'] = this.mountAsTrigger('.js-order-form-trigger', 'OrderFormTrigger', OrderFormTrigger) 
    }
 }
